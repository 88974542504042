<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>
      {{$t('filter')}}
      </h5>
      <b-button variant="secondary" class="float-right text-capitalize" @click="$emit('update-bridge-data')">{{ $t('actions.update') + " " + $tc('bridge.title', 2)}}</b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2 text-capitalize"
        >
            {{$tc('account.title',2)}}
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="accountFilter"
            :options="accountOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:accountFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    accountOptions: {
      type: Array,
      default: null,
    },
    accountFilter: {
      type: Array,
      default: null,
    },
  },
}
</script>